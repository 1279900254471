/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import styled from "@emotion/styled"
import algoliasearch from "algoliasearch/lite"
import {
  InstantSearch,
  SearchBox,
  Index,
  Hits,
  Configure,
} from "react-instantsearch-dom"

import PostPreview from "./PostPreview"
import { AllResults, IndexResults, indices } from "../Search/common"
import { SearchIcon, CloseIcon } from "../../assets/icons"

const searchClient = algoliasearch(
  "4JYVX00ZTJ",
  "84f2bc3c29d61feac2b894a503d00162"
)

const SearchBar = React.memo(() => {
  return (
    <SearchArea
      sx={{
        width: ["94%", "100%"],
        top: [100, 200],
        height: [64, 70],
        input: {
          fontFamily: "body",
        },
      }}
    >
      <InstantSearch searchClient={searchClient} indexName="verbs">
        <SearchBox
          searchAsYouType={false}
          autoFocus={true}
          submit={<SearchIcon sx={{ height: [22, 26], fill: "primary" }} />}
          reset={<CloseIcon sx={{ height: [24, 30], fill: "primary" }} />}
          translations={{
            placeholder: "Conjugate a verb or search a topic",
          }}
        />
        <HitArea sx={{ top: [52, 64], h4: { bg: "secondary" } }}>
          {indices(true).map(({ id, hits, className }) => (
            <Index key={id} indexName={id.toLowerCase()}>
              <IndexResults>
                <Configure hitsPerPage={hits} />
                <div className={`hit-section ${className ? className : ""}`}>
                  <h4>{id}</h4>
                  <Hits hitComponent={PostPreview} />
                </div>
              </IndexResults>
            </Index>
          ))}
          <AllResults>
            <div className="no-results">No results</div>
          </AllResults>
        </HitArea>
      </InstantSearch>
    </SearchArea>
  )
})

const SearchArea = styled.div`
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  max-width: 580px;
  z-index: 1;
  margin: 0 auto;
  display: flex;
  align-items: stretch;

  .ais-SearchBox {
    width: 100%;
  }

  .ais-SearchBox-form {
    background: #fff;
    border-radius: 5px;
    overflow: hidden;
    display: flex;
    height: 100%;
    width: 100%;
    position: relative;

    input {
      order: 2;
      flex: 1;
      -webkit-appearance: none;
      font-size: 20px;
      border: none;
      outline: none;
    }
  }

  .ais-SearchBox-reset {
    position: absolute;
    right: 0;
    bottom: 0;
    top: 0;
    border: none;
    border-left: 1px solid #d6d6d6;
    background: #f7f7f7;
    padding: 0 15px;
  }

  .ais-SearchBox-submit {
    padding: 0 20px;
    order: 1;
    border: none;
    background: none;
  }

  @media screen and (max-width: 768px) {
    .ais-SearchBox-submit {
      padding: 0 15px;
    }

    .ais-SearchBox-form input {
      font-size: 18px;
    }
  }
`

const HitArea = styled.div`
  position: absolute;
  left: 0;
  width: 100%;

  h4 {
    color: #ffffff;
    border-bottom: 1px solid gainsboro;
    text-transform: uppercase;
    font-size: 16px;
    letter-spacing: 2px;
    font-weight: 600;
    padding: 4px 20px;
  }

  .no-results {
    padding: 20px;
    background: #efeeee;
    color: #636363;
    opacity: 0;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    overflow: hidden;
    animation: fadeIn ease 0.2s forwards 0.1s;
    border-top: 1px solid gainsboro;
  }

  .verb-results ul {
    display: grid;
    grid-template-columns: 1fr 1fr;
    background: #dbdbdc;
    grid-column-gap: 1px;
  }

  .hit-section {
    overflow: hidden;
    box-shadow: 1px 7px 8px 1px rgba(0, 0, 0, 0.15);
    opacity: 0;
    animation: fadeInUp 0.2s ease forwards 0.2s;

    &:last-child {
      border: none;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
    }
  }
`

export default SearchBar
