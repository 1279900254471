/** @jsx jsx */
import { jsx } from "theme-ui"
import { Link } from "gatsby"
import styled from "@emotion/styled"

import AdBox from "../Ad"
import { TrendingIcon, CommunityIcon } from "../../assets/icons"
import { verbSlug } from "../../utils/helper"
import topVerbs from "../../config/top-verbs"

export default ({ language }) => {
  return (
    <Grid
      sx={{
        gridTemplateColumns: ["1fr", "repeat(3, 1fr)"],
      }}
    >
      <VerbTile className="tile" color={language.color_primary}>
        <div className="heading">
          <TrendingIcon />
          <h4>Popular Conjugations</h4>
        </div>
        <ol>
          {topVerbs()
            .trending.slice(0, 5)
            .map((item, index) => (
              <li key={index}>
                <Link to={verbSlug(item)}>
                  <span>{item}</span>
                </Link>
              </li>
            ))}
        </ol>
        <Link
          className="more heading"
          sx={{
            bg: "primary",
          }}
          to="/verbs"
        >
          More Verbs
        </Link>
      </VerbTile>
      <CommunityTile
        className="tile"
        sx={{
          backgroundImage: `url(${CommunityIcon})`,
          bg: language.color_secondary,
        }}
      >
        <h4>
          Community
          <br />
          Powered
        </h4>
        <p>
          {process.env.GATSBY_LANGUAGE} Study Guide is an open sourced language
          project. Any user can submit a translation or make a correction.
        </p>
        <p>Help other language learners improve by sharing your knowledge.</p>
        <Link
          className="more heading"
          sx={{
            color: "#fff",
            "&:hover": {
              color: "secondary",
              bg: "#fff",
            },
          }}
          to="/contribute"
        >
          Learn More
        </Link>
      </CommunityTile>
      <div
        className="tile"
        sx={{
          p: 10,
          display: "flex",
          alignItems: "center",
          bg: "#fff",
        }}
      >
        <AdBox type="small" />
      </div>
    </Grid>
  )
}

const Grid = styled.div`
  display: grid;
  grid-gap: 50px;

  .tile {
    min-height: 345px;
  }
`

const CommunityTile = styled.div`
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  color: #fff;
  text-align: center;

  h4 {
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 5px;
    font-weight: 600;
    padding: 30px 20px 20px;
    font-size: 25px;
  }

  p {
    margin: 0 30px 20px;
    font-family: Avenir Next;
    font-size: 17px;
  }

  .more {
    border: 2px solid #fff;
    padding: 10px 20px;
    font-weight: 500;
    display: inline-block;
    margin: 5px auto 30px;
    text-align: center;
    font-size: 19px;
    border-radius: 3px;
    transition: all ease 0.25s;
  }
`

const VerbTile = styled.div`
  padding: 10px 20px 20px;
  background: #fff;

  .heading {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 20px 15px;
    border-bottom: 1px solid gainsboro;
  }

  ol {
    list-style: decimal;
    margin-left: 40px;
    line-height: 2;
    font-size: 20px;
    margin-top: 10px;
  }

  li {
    color: ${({ color }) => color};

    span {
      color: #2b2b2b;
      margin-left: 10px;
    }
  }

  svg {
    height: 35px;
    margin-right: 10px;
    fill: ${({ color }) => color};
  }

  h4 {
    font-size: 24px;
  }

  .more {
    display: block;
    text-align: center;
    border-radius: 3px;
    color: #ffffff;
    padding: 10px;
    margin: 20px 20px 10px;
    font-size: 21px;
    transition: all ease 0.25s;

    &:hover {
      transform: translateY(-5px);
      box-shadow: 6px 11px 9px -2px rgba(0, 0, 0, 0.15);
    }
  }
`
