/** @jsx jsx */
import { jsx } from "theme-ui"
import styled from "@emotion/styled"

import HeroImage from "../../assets/vector/french"
import { AlgoliaIcon } from "../../assets/icons"

export default () => {
  return (
    <Hero sx={{ height: ["250px", "58vh"] }}>
      <HeroImage />
      <AlgoliaLink
        href="https://algolia.com"
        target="_blank"
        rel="noopener noreferrer"
        sx={{ bottom: [15, 25], right: [20, 30], img: { height: [20, 25] } }}
      >
        <img src={AlgoliaIcon} alt="Powered by Algolia" />
      </AlgoliaLink>
    </Hero>
  )
}

const Hero = styled.div`
  background: linear-gradient(#2084db, #45a6de 70%);
  max-height: 520px;
  overflow: hidden;
  display: flex;
  align-items: center;
  position: relative;
`

const AlgoliaLink = styled.a`
  position: absolute;
  opacity: 0;
  animation: fadeIn ease 1s forwards 0.2s;

  img {
    height: 25px;
  }
`
