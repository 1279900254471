/** @jsx jsx */
import { jsx } from "theme-ui"
import { Link } from "gatsby"
import styled from "@emotion/styled"

import { ArrowIcon } from "../../assets/icons"
import * as vocabURLs from "../../assets/icons/vocab"
import * as phraseURLs from "../../assets/icons/phrasebook"
import { vocabMenu, phraseMenu } from "../Layout/menus"

export default ({ postType }) => {
  const title = postType === "vocabulary" ? "Vocabulary" : "Phrases"
  const menu = postType === "vocabulary" ? vocabMenu : phraseMenu
  const images = postType === "vocabulary" ? vocabURLs : phraseURLs

  return (
    <GridSection>
      <h2 sx={{ color: "primary", fontSize: [32, 38], mb: [20, 30] }}>
        Popular {title}
      </h2>
      <PostGrid
        sx={{ gridTemplateColumns: ["repeat(2, 1fr)", "repeat(4, 1fr)"] }}
      >
        {menu.map(({ label, path, featured }) =>
          featured ? (
            <Link key={path} to={path}>
              <div className="tile" sx={{ color: "primary" }}>
                <img
                  sx={{ height: ["80px", "150px"] }}
                  src={images[label.split(" ")[0].toLowerCase()]}
                  alt={label}
                />
                <div
                  className="heading"
                  sx={{
                    fontSize: [18, 23],
                    m: ["5px 0 0", "10px 0"],
                    fontFamily: "heading",
                  }}
                >
                  {label}
                </div>
              </div>
            </Link>
          ) : null
        )}
      </PostGrid>
      <Link
        className="more heading"
        sx={{
          fontFamily: "heading",
          color: "primary",
          width: ["100%", "auto"],
        }}
        to={`/${postType}`}
      >
        More {title}
        <ArrowIcon />
      </Link>
    </GridSection>
  )
}

const GridSection = styled.div`
  text-align: right;

  h2 {
    text-align: left;
  }

  .more {
    box-sizing: border-box;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 15px 15px 15px 25px;
    border: 1px solid gainsboro;
    background: white;
    margin: 20px 0 60px;
    font-size: 18px;

    svg {
      margin-left: 15px;
      fill: #c1c1c1;
      border: 1px solid gainsboro;
      border-radius: 50%;
      height: 20px;
      padding: 2px;
    }
  }
`

const PostGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;

  .tile {
    background: #fff;
    text-align: center;
    border: 1px solid #e0e0e0;
    padding: 20px;
    transition: all ease 0.2s;

    &:hover {
      transform: translateY(-10px);
      box-shadow: 6px 11px 9px -2px rgba(0, 0, 0, 0.05);
    }
  }
`
