/** @jsx jsx */
import { jsx } from "theme-ui"
import { Fragment } from "react"
import { graphql } from "gatsby"
import { Global } from "@emotion/core"
import { ContentWrapper, Main, Section } from "gatsby-theme-elements"

import Hero from "../components/Home/Hero"
import PostGrid from "../components/Home/PostGrid"
import WelcomeSection from "../components/Home/WelcomeSection"
import ColumnSection from "../components/Home/ColumnSection"
import Search from "../components/Home/Search"
import SEO from "../components/Seo"
import language from "../config/language"

const IndexPage = ({ data }) => {
  const currentImage =
    data.allFile.nodes[Math.floor(Math.random() * data.allFile.nodes.length)]

  return (
    <Fragment>
      <SEO title="Home" />
      <Global
        styles={{
          "#__elements header": {
            borderBottom: "1px solid rgba(255, 255, 255, 0.2)",
            position: "absolute",
            background: language.color_home_header,
            width: "100%",
          },
        }}
      />
      <Hero />
      <Search />
      <ContentWrapper maxWidth="100%">
        <Main>
          <Section
            padding={["40px 20px 30px", "80px 20px"]}
            maxWidth={1200}
            sx={{
              opacity: 0,
              animation: "fadeInUp 0.8s ease forwards .25s",
            }}
          >
            <WelcomeSection image={currentImage.childImageSharp.fluid} />
          </Section>

          <Section
            maxWidth={1200}
            background="#f2f2f3"
            padding="50px 20px 60px"
          >
            <ColumnSection language={language} />
          </Section>

          <Section
            background="#fbfbfb"
            maxWidth={1200}
            padding={["20px", "80px 20px"]}
          >
            <PostGrid postType="vocabulary" />
            <PostGrid postType="phrasebook" />
          </Section>
        </Main>
      </ContentWrapper>
    </Fragment>
  )
}

export const query = graphql`
  query homeImage {
    allFile(
      filter: {
        extension: { regex: "/(jpg)/" }
        relativeDirectory: { eq: "home" }
      }
    ) {
      nodes {
        ...ImageFragment
      }
    }
  }
`

export default IndexPage
