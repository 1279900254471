/** @jsx jsx */
import { jsx } from "theme-ui"
import { useState } from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import styled from "@emotion/styled"

import { ArrowIcon } from "../../assets/icons"
import { quickLinks } from "../Layout/menus"

const welcomeMsg = () => {
  switch (process.env.GATSBY_LANGUAGE) {
    case "French":
      return "Bienvenue!"
    default:
      return
  }
}

export default ({ image }) => {
  const [hover, setHover] = useState(false)
  return (
    <WelcomeSection sx={{ gridTemplateColumns: ["1fr", ".6fr .4fr"] }}>
      <div className="featured">
        <Img fluid={image} />
        <Overlay sx={{ fontFamily: "heading" }}>
          <div className={`welcome-text ${hover ? "active" : null}`}>
            <div>Welcome!</div>
            <div className="translation">{welcomeMsg()}</div>
          </div>
          <Link
            to="/about"
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
          >
            Learn more about {process.env.GATSBY_LANGUAGE} Study Guide{" "}
            <ArrowIcon />
          </Link>
        </Overlay>
      </div>

      <QuickLinks sx={{ fontSize: ["15px", "17px"] }}>
        {quickLinks.map(({ title, path, icon, color, description }) => (
          <Link key={path} to={path}>
            <Icon
              className="icon"
              sx={{
                bg: color,
                borderColor: color,
                // "&:hover": { bg: "transparent", svg: { fill: color } },
              }}
            >
              {icon}
            </Icon>
            <div className="link-text">
              <h4 sx={{ color: "primary", letterSpacing: [3, 4] }}>{title}</h4>
              <p>{description}</p>
            </div>
          </Link>
        ))}
      </QuickLinks>
    </WelcomeSection>
  )
}

const WelcomeSection = styled.div`
  display: grid;
  grid-column-gap: 50px;
  grid-row-gap: 20px;

  .featured {
    background: gainsboro;
    height: 100%;
    min-height: 330px;
    position: relative;
    border-radius: 5px;
    overflow: hidden;
  }
  .gatsby-image-wrapper {
    height: 100%;
  }
`

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(0, black 1%, transparent 32%);

  .welcome-text {
    font-size: 50px;
    font-weight: 500;
    text-align: center;
    margin-top: -45px;
    padding: 20px 40px;
    color: #fff;
    background: rgba(0, 0, 0, 0.28);
    text-shadow: 1px 1px 8px rgba(0, 0, 0, 0.5);
    transition: all ease 0.4s;

    &.active {
      background: rgba(0, 0, 0, 0.75);
    }
  }

  .translation {
    margin-top: -15px;
    font-style: italic;
  }

  a {
    position: absolute;
    color: #fff;
    bottom: 15px;
    padding: 0 20px;
    left: 0;
    right: 0;
    font-size: 26px;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      height: 32px;
      fill: #fff;
      margin-left: 10px;
      transition: all ease 0.25s;
    }

    &:hover {
      svg {
        transform: translateX(10px);
      }
    }
  }
`

const QuickLinks = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  a {
    color: #626262;
    border-bottom: 1px solid gainsboro;
    padding: 25px 0;
    display: flex;
    align-items: center;

    .link-text {
      position: relative;
      transition: transform ease 0.25s;
    }

    &:hover {
      .link-text {
        transform: translateX(4px);
      }
      svg {
        transform: scale(1.2);
      }
    }
  }

  a:last-of-type {
    border: none;
  }

  div {
    flex: 1;
  }

  h4 {
    text-transform: uppercase;
    letter-spacing: 2.5px;
    font-weight: 600;
  }
`

const Icon = styled.div`
  height: 75px;
  max-width: 110px;
  margin-right: 20px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 3px solid;
  transition: background-color ease 0.4s;

  svg {
    height: 40px;
    width: 40px;
    fill: #fff;
    transition: transform ease 0.4s;
  }
`
