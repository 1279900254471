/** @jsx jsx */
import { jsx } from "theme-ui"
import { Link } from "gatsby"
import styled from "@emotion/styled"
import { Highlight, Snippet } from "react-instantsearch-dom"
import { NextIcon } from "../../assets/icons"

import * as vocabURLs from "../../assets/icons/vocab"
import * as phraseURLs from "../../assets/icons/phrasebook"

export default ({ hit }) => {
  // Verb render
  if (hit.path.startsWith("/verb")) {
    return (
      <HitLink sx={{ mark: { bg: "highlight_border" } }} to={hit.path}>
        <LinkContent className="link-content">
          <Highlight hit={hit} attribute="verb" tagName="mark" />
          <NextIcon />
        </LinkContent>
      </HitLink>
    )
  }
  // Phrasebook render
  if (hit.path.startsWith("/phrasebook")) {
    return (
      <HitLink sx={{ mark: { bg: "highlight_border" } }} to={hit.path}>
        <LinkContent className="link-content">
          <div sx={{ display: "flex", flex: 1 }}>
            <img
              sx={{ height: ["30px", "50px"] }}
              src={phraseURLs[hit.title.split(" ")[0].toLowerCase()]}
              alt={hit.title}
            />
            <div className="results">
              <h5 sx={{ color: "primary", fontSize: [16, 19] }}>{hit.title}</h5>
              <Snippet hit={hit} attribute="translations" tagName="mark" />
            </div>
          </div>
          <NextIcon />
        </LinkContent>
      </HitLink>
    )
  }
  // Vocab render
  return (
    <HitLink sx={{ mark: { bg: "highlight_border" } }} to={hit.path}>
      <LinkContent className="link-content">
        <div sx={{ display: "flex", flex: 1 }}>
          <img
            sx={{ height: ["30px", "50px"] }}
            src={vocabURLs[hit.title.split(" ")[0].toLowerCase()]}
            alt={hit.title}
          />
          <div className="results">
            <h5 sx={{ color: "primary", fontSize: [16, 19] }}>{hit.title}</h5>
            <Snippet hit={hit} attribute="translations" tagName="mark" />
          </div>
        </div>
        <NextIcon />
      </LinkContent>
    </HitLink>
  )
}

const LinkContent = styled.div`
  transition: all ease 0.3s;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  svg {
    height: 20px;
    fill: #888;
    transition: all ease 0.3s;
  }
`

const HitLink = styled(Link)`
  border-bottom: 1px solid gainsboro;
  display: flex;
  align-items: center;
  padding: 15px 20px;
  font-weight: bold;
  background: #fbfbfb;
  transition: all ease 0.3s;

  img {
    margin-right: 20px;
    background: #f1f1f1;
    border: 1px solid gainsboro;
    border-radius: 3px;
    padding: 3px;
    transition: all ease .3s;
}
  }

  .results {
    flex: 1;
  }

  .ais-Snippet {
    color: #2a2a2a;
    font-weight: normal;
  }

  mark {
    font-weight: bold;
  }

  span {
    transition: all ease .3s;
  }

  &:hover {
    background: #f2f2f2;
    .link-content, .link-content svg {
      transform: translateX(4px);
    }
    img {
      background: #fff;
    }
  }
`
